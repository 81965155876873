<template>
  <div class="w-full">
    <p v-if="isLabelTruncated" class="text text-sm text-gray-500 mb-4">
      <span v-if="isRequired" class="text-red-600 mr-1">*</span>
      {{ placeholder }}
    </p>

    <div :class="textInputClassList">
      <input
        :id="name"
        v-model="value"
        class="textInput__input"
        :class="{
          'textInput__input--hasValue':
            (value != null && value !== '') || type === 'date',
          'textInput__input--hasSuffix': suffix && suffix.length > 0,
          'textInput__input--isValid': meta.valid,
          'textInput__input--hasError': isErrorState || errors.length > 0,
          'textInput__input--hasCurrency': hasCurrency,
          'textInput--disabled': disabled,
          'textInput--truncated': isLabelTruncated
        }"
        :type="inputType"
        :disabled="disabled"
        required
        :aria-label="placeholder"
        data-testid="input"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
        @keypress="onKeypress"
      />

      <label
        v-if="!isLabelTruncated"
        ref="label"
        :for="name"
        class="textInput__label"
        ><span v-if="isRequired" class="text-red-600 mr-1">*</span
        >{{ placeholder }}</label
      >

      <span v-if="hasCurrency" class="textInput__currency"> £ </span>

      <span v-if="suffix && suffix.length > 0" class="textInput__suffix">
        <span class="value">{{ value }}</span> {{ suffix }}
      </span>

      <template v-if="meta.dirty">
        <OsIcon
          v-if="validate && !isErrorState && !!value && errors.length === 0"
          class="textInput__tickIcon text-green-500"
          name="Tick"
        />
      </template>

      <OsIcon
        v-if="(validate && errors.length > 0) || isErrorState"
        class="textInput__errorIcon text-red-500"
        name="Error"
      />

      <OsError v-if="showErrors && errors.length">
        {{ errors[0] }}
      </OsError>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineOptions({
  name: 'InputText'
})

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: false,
    default: undefined
  },

  // validation
  name: {
    type: String,
    required: true
  },

  pattern: {
    type: String,
    required: false,
    default: null
  },

  validate: {
    type: Boolean,
    required: false,
    default: false
  },

  showErrors: {
    type: Boolean,
    required: false,
    default: true
  },

  validateOnMount: {
    type: Boolean,
    default: false
  },

  rules: {
    type: String,
    required: false,
    default: undefined
  },

  // force error state
  isErrorState: {
    type: Boolean,
    default: false
  },

  type: {
    type: String,
    default: 'text'
  },

  suffix: {
    type: String,
    default: ''
  },

  placeholder: {
    type: String,
    required: false,
    default: ''
  },

  hasCurrency: {
    type: Boolean,
    required: false,
    default: false
  },

  formatNumber: {
    type: [Boolean, Function],
    required: false,
    default: false
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false
  },

  isOcd: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['blur', 'update:modelValue'])

const label = ref<HTMLElement>()
const isInputFocused = ref<boolean>(false)

const { rules: rulesToUse } = toRefs(props)

const { value, errors, meta, setTouched, handleChange } = useField(
  () => props.name,
  rulesToUse,
  {
    initialValue: props.modelValue,
    syncVModel: true,
    validateOnMount: props.validateOnMount
  }
)

const isFormattedNumber = computed(
  () => props.hasCurrency || props.formatNumber
)

const inputType = computed(() => {
  if (isFormattedNumber.value) {
    return 'text'
  }

  return props.type
})

const textInputClassList = computed(() => ({
  textInput: true,
  'textInput--disabled': props.disabled,
  [`textInput--${inputType.value}`]: !!inputType.value
}))

const formatNumberMethod = () => {
  if (!value.value) return ''

  if (typeof props.formatNumber === 'function') {
    value.value = props.formatNumber(value.value)
  } else {
    value.value = formatWithCommasAndDecimals(value.value as string)
  }
}

const isKioskMode = useState('kioskmode')

const showKioskKeyboard = (isShown: Boolean) => {
  try {
    if (window.ScalaSetVariable) {
      window.ScalaSetVariable('Keyboard', isShown)
    } else {
      // eslint-disable-next-line no-console
      console.warn('ScalaSetVariable: Function not found')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('ScalaSetVariable : Unable to call ScalaSetVariable', e)
  }
}

const onKeypress = (event: KeyboardEvent) => {
  if (props.pattern && !event.key.match(props.pattern)) {
    event.preventDefault()
  }
  preventNonNumericalInput(event)
}

const onFocus = () => {
  isInputFocused.value = true
  if (isKioskMode.value) {
    showKioskKeyboard(true)
  }
}

const onBlur = (event: any) => {
  isInputFocused.value = false

  if (isFormattedNumber.value) {
    formatNumberMethod()
  }

  setTouched(true)

  emit('blur', event)

  if (isKioskMode.value) {
    showKioskKeyboard(false)
  }
}

const isRequired = computed(() => /\brequired\b/.test(props.rules || ''))

// This is prevent non numerical inputs in firefox
const preventNonNumericalInput = (event: KeyboardEvent) => {
  if (inputType.value !== 'number') return

  if (!event.key.match(/^[0-9]+$/)) event.preventDefault()
}

const isLabelTruncated = ref(false)

onMounted(() => {
  if (isFormattedNumber.value) formatNumberMethod()
  if (label.value && props.isOcd) {
    isLabelTruncated.value = label.value.scrollWidth > label.value.clientWidth
  }
})

watch(
  () => props.rules,
  () => {
    handleChange(value.value)
  }
)

watch(
  value,
  () => {
    if (isFormattedNumber.value && !isInputFocused.value)
      nextTick(formatNumberMethod)
  },
  { immediate: true }
)
</script>

<style lang="scss">
.textInput {
  position: relative;
  font-family: $mb-font-text;
  font-size: rem(16);

  &--tel {
    max-width: 193px;
  }

  &--postcode {
    max-width: 196px;
  }

  &--uppercase input {
    text-transform: uppercase;
  }

  &--disabled {
    input,
    label {
      opacity: 0.3;
    }
  }
}

.textInput--date {
  .textInput__tickIcon,
  .textInput__errorIcon {
    position: absolute;
    right: 45px;
    top: 32px;
  }
}

.textInput__tickIcon,
.textInput__errorIcon {
  position: absolute;
  right: 16px;
  top: 21px;
}

.textInput__input {
  line-height: 1;
  height: 56px;
  width: 100%;
  padding: rem(16);
  border-radius: 4px;
  border: solid 1px $grey--light;
  background-color: #fff;
  font-family: $mb-font-text;
  font-size: rem(18);
  color: $black--light;
}

.textInput__label {
  color: $grey--darkest;
  font-size: rem(18);
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 17px;
  transition: 0.2s ease all;
  // Overflow ellipsis for long labels
  max-width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textInput__currency {
  position: absolute;
  top: 27px;
  left: 16px;
  font-family: $mb-font-text;
  font-size: rem(18);
  line-height: 1.25;
  color: $black--light;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.7);
  transition: 0.2s ease all;
}

.textInput__suffix {
  position: absolute;
  top: 0;
  left: 0;
  font-family: $mb-font-text;
  font-size: rem(18);
  line-height: 1.25;
  color: $black--light;
  padding: rem(26) rem(16) rem(0.6);
  pointer-events: none;
  display: inline-flex;
}

.textInput__suffix {
  .value {
    opacity: 0;
  }
}

@-moz-document url-prefix() {
  .textInput__currency {
    top: 27px;
  }
}
.textInput__input:-webkit-autofill ~ label,
.textInput__input--isFocussed ~ label,
.textInput__input:focus ~ label,
.textInput__input--hasValue ~ label {
  top: 8px;
  font-size: rem(14);
}

.textInput__input:-webkit-autofill,
.textInput__input--isFocussed,
.textInput__input:focus,
.textInput__input--hasValue {
  padding: rem(26) rem(16) rem(6);

  &.textInput__input--hasCurrency {
    padding-left: rem(28);

    ~ .textInput__currency {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
}

.textInput__input--hasError {
  border: 1px solid $red;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

// Hack to remove chrome autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.textInput--truncated,
.textInput--truncated:focus {
  padding: rem(6) rem(16) rem(6);
}
</style>
